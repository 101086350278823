
import './App.css';

function App() {
  return (
    <div className="App">
      Hey there page under maintanance. Please try again after few days.
    </div>
  );
}

export default App;
